<template class="">
  <div>
    <div class="row">
      <div class="col-md-12">
        <div class="row mx-1 mt-1">
          <div class="col-md-6 text-center">
            <div
              class="card d-flex align-items-center flex-column"
              style="min-height: 49vh"
            >
              <span class="font mt-1 mb-1">Monitores UptimeRobot</span>
              <div style="min-height: 17vh; width: 90%">
                <div
                  id="uptimerobot"
                  :style="'color: white; border-radius: 4px; font-size: 5vh; min-height: 15vh;'"
                  :class="
                    'p-0 d-flex justify-content-center align-items-center bg-' +
                    (monitor !== 'Carregando...'
                      ? status === 2
                        ? 'success'
                        : status === 0
                        ? 'warning'
                        : 'danger'
                      : 'secondary')
                  "
                >
                  {{ monitor }}
                </div>

              </div>
              <div
                class="w-75 mt-4mb-1 d-flex align-items-center justify-content-center"
                style="margin-top: 0.5vh"
              >
                <div
                  class="font d-flex mr-3 align-items-center justify-content-center mr-1"
                  style="font-size: 3.5vh; line-height: 3vh"
                >
                  <div
                    class="bg-success ml-1 mr-1"
                    style="border-radius: 100px; height: 3.5vh; width: 3.5vh"
                  ></div>
                  Online
                </div>
                <div
                  class="font d-flex mr-3 align-items-center justify-content-center"
                  style="font-size: 3.5vh; line-height: 3vh"
                >
                  <div
                    class="bg-danger ml-1 mr-1"
                    style="border-radius: 100px; height: 3.5vh; width: 3.5vh"
                  ></div>
                  Offline
                </div>
                <div
                  class="font d-flex align-items-center justify-content-center"
                  style="font-size: 3.5vh; line-height: 3vh"
                >
                  <div
                    class="bg-warning ml-1 mr-1"
                    style="border-radius: 100px; height: 3.5vh; width: 3.5vh"
                  ></div>
                  Pausado
                </div>
              </div>
              <div class="w-100 mt-4 mb-1 text-center d-flex">
                <span class="font col-3"> Sensores <br />{{ total }} </span>
                <span class="font col-3 text-success">
                  Online <br />{{ online }}
                </span>
                <span class="font col-3 text-danger">
                  Offline <br />{{ offline }}
                </span>
                <span class="font col-3 text-warning">
                  Pausado <br />{{ paused }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <div
              class="card d-flex align-items-center flex-column"
              style="min-height: 49vh"
            >
            <span class="font mt-1 mb-1">
              {{ showOffline ? "Monitores Offline" : "Monitores Pausados" }}
            </span>
              <div class="row">
                <div
                  :class="(showOffline ? monitor.status === 9 : monitor.status === 0) ? 'col-6 px-1 text-left' : 'd-none'"
                  v-for="monitor in uptime"
                >
                  <div
                    class="font d-flex align-items-center justify-content-start mr-1"
                    style="font-size: 3.5vh; line-height: 3.5vh"
                  >
                    <div
                      :class="showOffline ? 'bg-danger' : 'bg-warning'"
                      class="mx-1"
                      style="
                        border-radius: 100px;
                        height: 2vh !important;
                        width: 2vh !important;
                      "
                    ></div>
                    <b :class="showOffline ? 'text-danger' : 'text-warning'" style="font-size: 3vh">
                      {{ monitor.friendly_name }}
                    </b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <div
              class="card d-flex justify-content-center align-itens-center"
              style="min-height: 23vh"
            >
              <h4 class="mt-1 font" style="font-size: 4vh">
                Sistemas em Desenvolvimento
              </h4>
              <div
                class="d-flex align-items-center flex-column px-1"
                id="projects"
              >
                <h5 style="font-size: 4.5vh">{{ issue.project_name }}</h5>
                <div class="progress w-75">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    :style="'width:' + issue.tasks.done + '%;'"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    class="progress-bar bg-primary"
                    role="progressbar"
                    :style="'width: ' + issue.tasks.doing + '%;'"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    :style="'width: ' + issue.tasks.impeded + '%;'"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                  <div
                    class="progress-bar bg-secondary"
                    role="progressbar"
                    :style="'width: ' + issue.tasks.todo + '%;'"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
                <span style="font-size: 3.5vh"
                  >{{ Math.floor(issue.tasks.done) }}%</span
                >
              </div>
            </div>
          </div>
          <div class="col-md-6 text-center">
            <div
              class="card d-flex justify-content-center align-itens-center"
              style="height: 23vh"
            >
              <div class="row" id="sla">
                <div class="col-12" v-if="sla.id">
                  <div class="font">Chamados Abertos:</div>
                  <span style="font-size: 3.5vh">
                    <template v-if="!sla.dev">
                      <b>Sistema:</b> {{ sla.project.name }}<br />
                      <b>Responsável: </b>
                      <span v-if="sla.dev_acting">{{ sla.dev.name }}</span>
                      <span v-else>Nenhum Dev Alocado</span>
                      <br />
                      <b>SLA: </b>
                      <span
                        :class="
                          'badge badge-' +
                          (sla.sla.text.includes('Atrasado')
                            ? 'danger'
                            : 'success')
                        "
                      >
                        {{ sla.sla.text }}
                      </span>
                    </template>
                  </span>
                </div>
                <div
                  class="col-12 d-flex justify-content-center align-items-center"
                  style="height: 26vh"
                  v-else
                >
                  <div class="font">{{ texto_chamados }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div
              class="card d-flex justify-content-center align-items-center"
              style="min-height: 15vh"
            >
              <div
                class="row w-100 d-flex justify-content-center align-items-center"
                id="users"
              >
                <div class="col-2 text-center">
                  <span class="w-100 font-low text-center"
                    >{{ stopover.name.split(" ")[0] }}<br />
                    <span
                      :class="
                        'w-100 text-center text-' +
                        (stopover.daily_done ? 'success' : 'danger')
                      "
                      style="font-size: 2.5vh; font-weight: 700"
                    >
                      {{ stopover.dailyText }}
                    </span>
                  </span>
                </div>
                <div
                  class="col-10 d-flex justify-content-center align-items-center"
                  v-if="stopover.stopover.no_data"
                >
                  <span class="badge badge-danger"
                    ><h1>ESCALA NÃO PREENCHIDA</h1></span
                  >
                </div>
                <div class="col-10 row" v-else>
                  <div
                    class="col-2 text-center"
                    v-for="date in stopover.stopover"
                    :key="date"
                  >
                    <span class="font-low">{{ date.start_date_f }}</span
                    ><br />
                    <span
                      class="font-lower"
                      :style="'color: ' + date.home_office_color"
                      >{{ date.status_home }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="dailyModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="title"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="title">Faça sua daily</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-12 mb-1">
                <label>O que fez ontem?</label>
                <input
                  v-model="daily.yesterday"
                  class="form-control"
                  type="text"
                  :disabled="id ? true : false"
                />
              </div>
              <div class="col-md-12 mb-1">
                <label>O que vai fazer hoje?</label>
                <input
                  v-model="daily.today"
                  class="form-control"
                  type="text"
                  :disabled="id ? true : false"
                />
              </div>
              <div :class="'col-md-' + (dailyStatus ? '4' : '12')">
                <label>Houve algum impedimento?</label>
                <select
                  class="form-control"
                  v-model="dailyStatus"
                  :disabled="id ? 'true' : false"
                >
                  <option value="">Não</option>
                  <option value="1">Sim</option>
                </select>
              </div>
              <div class="col-md-8" v-if="dailyStatus">
                <label for="">Descreva-o por favor</label>
                <input
                  v-model="daily.impediment"
                  id="impedido"
                  class="form-control"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Cancelar
            </button>
            <button type="button" class="btn btn-primary" @click="saveDaily()">
              Avançar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";
import { BCard, BCardBody, BAvatar } from "bootstrap-vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { BNavItem } from "bootstrap-vue";

export default {
  data() {
    return {
      total: 0,
      online: 0,
      offline: 0,
      paused: 0,
      showOffline: true,
      dashboard: [],
      status: "",
      monitor: "Carregando...",
      issue: {
        name: "Carregando...",
        tasks: {
          impeded: "0",
          todo: "0",
          doing: "0",
          done: "0",
        },
      },
      stopover: {
        name: "",
        stopover: [],
        dailyText: "",
      },
      sla: [],
      texto_chamados: "Carregando...",
      daily: {
        yesterday: "",
        today: "",
      },
      dailyStatus: "",
      uptime: [],
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
    BNavItem,
  },
  setup() {
    var { skin } = useAppConfig();

    skin.value = "dark";

    const isDark = computed(() => skin.value === "dark");

    return { skin, isDark };
  },
  methods: {

    toggleMonitorView() {
      setInterval(() => {
        this.showOffline = !this.showOffline; // Alterna entre offline e pausado
      }, 10000); // Alterna a cada 10 segundos (ajuste conforme necessário)
    },
    uptimerobot() {
      const self = this;
      let api = self.$store.state.api + "uptimerobot";

      axios
        .get(api)
        .then((response) => {
          self.total = response.data.monitors.length;
          self.online = response.data.status.online;
          self.offline = response.data.status.offline;
          self.paused = response.data.status.paused;

          self.uptime = response.data.monitors;

          let i = 0;
          var intervalRobot = setInterval(() => {
            $("#uptimerobot").fadeIn(1000);

            if (i + 1 === response.data.monitors.length) {
              self.status = response.data.monitors[i].status;
              self.monitor = response.data.monitors[i].friendly_name;
              clearInterval(intervalRobot);
              self.uptimerobot();
              i = 0;
            } else {
              self.status = response.data.monitors[i].status;
              self.monitor = response.data.monitors[i].friendly_name;
              i++;
            }

            setTimeout(() => {
              $("#uptimerobot").hide();
            }, 9900);
          }, 10000);
        })
        .catch((error) => {
          self.monitor = "Falha ao conectar, tentando novamente...";
          var intervalRobot = setInterval(() => {
            clearInterval(intervalRobot);
            self.status = 9;
            self.monitor = "Carregando...";
            self.uptimerobot();
          }, 10000);
        });
    },
    issues() {
      const self = this;
      let api = self.$store.state.api + "issues_graphic";

      axios
        .get(api)
        .then((response) => {
          const data = response.data.data;
          if (data.length) {
            let i = 0;
            var intervalissue = setInterval(() => {
              $("#issues").fadeIn(1000);

              if (i + 1 === data.length) {
                self.issue = data[i];
                clearInterval(intervalissue);
                self.issues();
                i = 0;
              } else {
                self.issue = data[i];
                i++;
              }

              setTimeout(() => {
                $("#issues").hide();
              }, 9900);
            }, 10000);
          } else {
            self.issue = {
              project_name: "Não há Sistemas cadastrados",
              tasks: {
                done: 0,
                todo: 0,
                doing: 0,
                impeded: 0,
              },
            };
            var intervalissue = setInterval(() => {
              clearInterval(intervalissue);
              self.issues();
            }, 10000);
          }
        })
        .catch((error) => {
          self.issue = {
            project_name: "Falha ao conectar, tentando novamente...",
            tasks: {
              done: 0,
              todo: 0,
              doing: 0,
              impeded: 0,
            },
          };
          var intervalissue = setInterval(() => {
            clearInterval(intervalissue);
            self.issue.project_name = "Carregando...";
            self.issues();
          }, 10000);
        });
    },
    calendar() {
      const self = this;
      let api = self.$store.state.api + "stopover";

      axios
        .get(api)
        .then((response) => {
          if (response.data.length) {
            let i = 0;
            var intervalStopover = setInterval(() => {
              $("#users").fadeIn(1000);
              if (i + 1 === response.data.length) {
                self.stopover = response.data[i];
                if (response.data[i].daily_done) {
                  self.stopover.dailyText = "Daily Feita";
                } else {
                  self.stopover.dailyText = "Daily Não Feita";
                }
                clearInterval(intervalStopover);
                self.calendar();
                i = 0;
              } else {
                self.stopover = response.data[i];
                if (response.data[i].daily_done) {
                  self.stopover.dailyText = "Daily Feita";
                } else {
                  self.stopover.dailyText = "Daily Não Feita";
                }
                i++;
              }

              setTimeout(() => {
                $("#users").hide();
              }, 9900);
            }, 10000);
          } else {
            $("#users").fadeIn(1000);
            var intervalStopover = setInterval(() => {
              clearInterval(intervalStopover);
              $("#users").hide();
              self.calendar();
            }, 10000);
          }
        })
        .catch((error) => {
          var intervalStopover = setInterval(() => {
            clearInterval(intervalStopover);
            self.calendar();
          }, 10000);
        });
    },
    getSla() {
      const self = this;
      let api = self.$store.state.api + "open-sla";

      axios
        .get(api)
        .then((response) => {
          self.texto_chamados = "Carregando...";

          if (response.data.length) {
            let i = 0;
            var intervalSla = setInterval(() => {
              $("#sla").fadeIn(1000);

              if (i + 1 === response.data.length) {
                self.sla = response.data[i];
                clearInterval(intervalSla);
                self.getSla();
                i = 0;
              } else {
                self.sla = response.data[i];
                i++;
              }

              setTimeout(() => {
                $("#sla").hide();
              }, 9900);
            }, 10000);
          } else {
            $("#sla").fadeIn(1000);
            self.sla.id = 0;
            self.texto_chamados = "Não há chamados Abertos";

            var intervalSla = setInterval(() => {
              clearInterval(intervalSla);
              self.getSla();
            }, 10000);
          }
        })
        .catch((error) => {
          var intervalSla = setInterval(() => {
            self.sla.id = 0;
            self.texto_chamados = "Carregando...";

            clearInterval(intervalSla);
            self.getSla();
          }, 10000);
        });
    },
  },
  mounted() {
    const self = this;

    self.uptimerobot();
    self.issues();
    self.calendar();
    self.getSla();
    this.toggleMonitorView(); // Inicia alternância entre Offline e Pausado
  },
  created() {},
};
</script>

<style>
.font {
  font-size: 4.5vh;
  font-weight: 700;
}
.font-low {
  font-size: 3.5vh;
  font-weight: 700;
}
.font-lower {
  font-size: 3vh;
  font-weight: 700;
}
</style>
